<template>
  <nav>
    <v-navigation-drawer
      mini-variant
      expand-on-hover
      v-model="drawer"
      dark
      app
      class="gradients"
    >
      <v-list dense>
        <v-list-item
          :color="$store.state.secondaryColor"
          v-for="link in controlledAccessMenu"
          :key="link.text"
          class="py-0"
          @click="redirect(link.text, link.route)"
        >
          <v-list-item-icon>
            <v-icon small> {{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            class="caption white--text darken-1 font-weight-light text-capitalize"
          >
            {{ link.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list-item>
          <v-menu
            open-on-hover
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon>mdi-account-circle-outline</v-icon></v-list-item-icon
              >
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <img :src="user.avatar" alt="profile" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">{{
                      user.names.first +
                      " " +
                      user.names.middle +
                      " " +
                      user.names.last
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="caption">{{
                      ownerType
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <div v-for="menu in controlledAccessUserMenu" :key="menu.icon">
                  <v-list-item @click="redirect(menu.text, menu.route)">
                    <v-list-item-action>
                      <v-icon left dense>{{ menu.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title class="blue-grey--text subtitle-2">{{
                      menu.text
                    }}</v-list-item-title>
                  </v-list-item>
                </div>
                <v-divider></v-divider>
                <v-list-item @click="logout">
                  <v-list-item-action
                    ><v-icon left dense>mdi-exit-to-app</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="blue-grey--text subtitle-2">{{
                    $t("sign out")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <!-- TOP BAR -->

    <v-app-bar app dense class="elevation-1 white">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-card @click="reload()" class="transparent pa-2" flat>
        <v-img
          contain
          src="@/assets/Tlogo.png"
          width="120"
          height="auto"
        ></v-img>
      </v-card>
      <v-toolbar-title
        class="blue-grey--text text-uppercase font-weight-light title darken-2"
        v-if="selected"
      >
        <span>{{ busName }} :</span>
        {{ $store.state.windowTitle }}
      </v-toolbar-title>
      <v-toolbar-title
        class="blue-grey--text text-uppercase font-weight-light title darken-2"
        v-else
      >
        {{ $store.state.windowTitle }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div v-if="checkConnection()">
        <span v-if="user == null">{{ $t("loading...") }}</span>
      </div>

      <div v-else>
        <span class="font-weight-black red--text">{{
          $t("system is offline")
        }}</span>
      </div>

      <v-menu offset-y v-model="menuLang" class="">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" style="cursor: pointer">
            <flag :iso="$i18n.locale == 'en' ? 'us' : $i18n.locale" />
            <span class="ml-2 subtitle-2">{{
              $i18n.locale.toUpperCase()
            }}</span>
          </v-btn>
        </template>
        <v-list dense max-height="300" class="overflow-y-auto pa-0" tile>
          <div v-for="item in languages" :key="item.lang">
            <v-list-item @click="switchLanguage(item.lang)" class="">
              <v-list-item-icon>
                <flag :iso="item.flag" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="caption">
                  {{ item.lang.toUpperCase() }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-menu>

      <v-menu
        v-model="notificationsMenu"
        :close-on-content-click="false"
        :nudge-width="150"
        offset-x
        max-height="350"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :content="getNumberOfNotifications(notifications)"
            :value="getNumberOfNotifications(notifications)"
            :color="$store.state.primaryColor"
            overlap
            class="ma-3"
          >
            <v-icon v-bind="attrs" v-on="on">mdi-bell-ring-outline</v-icon>
          </v-badge>
        </template>

        <v-card>
          <v-list>
            <v-list-item class="grey lighten-2">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="text-h5">{{
                    $t("notifications")
                  }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <!-- ############## -->
            <v-list-item v-for="item in notifications" :key="item.id">
              <v-list-item-content>
                <v-list-item-title class="body-2 font-weight-black">{{
                  item.message
                }}</v-list-item-title>
                <v-list-item-subtitle
                  class="caption font-italic font-weight-regular"
                  >{{
                    formatDate(item.dateTime) +
                    "(" +
                    formatDateMinuteTime(item.dateTime) +
                    ")"
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-btn icon @click="confirmDelete(item.id)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="notificationsMenu = false">
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("delete notification") }}
        </v-card-title>
        <v-card-text class="pa-4">
          {{
            $t(
              "are you sure you want to delete this notification from the system"
            )
          }}
          ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogDelete = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            :loading="loadingDelete"
            @click="deleteNotification()"
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackSuccess"
      bottom
      right
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("notification deleted") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackError"
      bottom
      right
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not delete notification. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </nav>
</template>

<script>
import jwtdecode from "jwt-decode";
import db from "@/plugins/fb";

import { format } from "date-fns";

export default {
  props: ["user"],
  data: () => ({
    drawer: true,
    menuLang: false,
    languages: [
      {
        flag: "us",
        lang: "en",
      },
      {
        flag: "fr",
        lang: "fr",
      },
    ],
    snackError: false,
    snackSuccess: false,
    dialogDelete: false,
    dialogm1: "",
    dialog: false,

    loadingDelete: false,
    notificationsMenu: false,
    notificationId: "",

    //check if user is logged in

    isLoggedIn: false,
    currentUser: false,
    notifications: [],
    products: [],
    internetConnection: "",
    userAccess: 0,
    ownerType: "",
    busName: "",
  }),

  created() {
    window.addEventListener("online", this.checkConnection);
    window.addEventListener("offline", this.checkConnection);
    this.getBusinessName();
    this.checkAccessLevel();
    this.getNotifications();
  },

  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusiness() {
      return this.$store.getters.getBusinesses;
    },

    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },

    controlledAccessMenu() {
      return this.links.filter((val) => {
        if (val.level.includes(this.userAccess)) {
          return val;
        }
      });

      // return this.links.filter((val) => {
      //   //access right for link is greater or equal than user provide it.
      //   if (val.level >= this.userAccess) {
      //     return val;
      //   }
      // });
    },

    controlledAccessUserMenu() {
      return this.userMenu.filter((val) => {
        if (val.level.includes(this.userAccess)) {
          return val;
        }
      });
    },
    links() {
      return [
        // changed daashboard link
        {
          icon: "mdi-view-dashboard",
          text: this.$t("dashboard"),
          route: "DashboardShop",
          level: [1, 2],
        },
        {
          icon: "fas fa-cart-plus",
          text: this.$t("POS"),
          route: "POS",
          level: [1],
        },

        {
          icon: "fas fa-cart-plus",
          text: this.$t("POS"),
          route: "RestaurantPOS",
          level: [2, 4],
        },
        {
          icon: "mdi-food-fork-drink",
          text: this.$t("orders"),
          route: "Orders",
          level: [2, 4, 3, 5],
        },

        {
          icon: "fas fa-store",
          text: this.$t("stores"),
          route: "ViewStores",
          level: [1],
        },
        {
          icon: "fas fa-barcode",
          text: this.$t("stock"),
          route: "StockMenu",
          level: [1, 2],
        },
        {
          icon: "mdi-hammer-wrench",
          text: this.$t("services"),
          route: "ViewServices",
          level: [1, 2],
        },
        {
          icon: "money",
          text: this.$t("sales"),
          route: "ViewSales",
          level: [1, 2],
        },
        {
          icon: "mdi-scale-balance",
          text: this.$t("accounts"),
          route: "DashboardAccount",
          level: [1, 2],
        },
        {
          icon: "fas fa-truck",
          text: this.$t("suppliers"),
          route: "ViewSuppliers",
          level: [1, 2],
        },
        {
          icon: "fas fa-file-pdf",
          text: this.$t("reports"),
          route: "SalesReports",
          level: [1, 2],
        },
        {
          icon: "fas fa-users",
          text: this.$t("human resource"),
          route: "HRDashboard",
          level: [1, 2, 3, 4, 5, 6],
        },
        {
          icon: "mdi-clipboard-list-outline",
          text: this.$t("project management"),
          route: "ViewProjects",
          level: [1, 2, 3, 4, 5, 6],
        },
      ];
    },
    userMenu() {
      return [
        {
          icon: "fas fa-user",
          text: this.$t("profile"),
          route: "ViewProfile",
          level: [0, 1],
        },
        {
          icon: "mdi-storefront",
          text: this.$t("select shop"),
          route: "SelectBusiness",
          level: [0, 1],
        },
        {
          icon: "mdi-cog",
          text: this.$t("settings"),
          route: "SystemGeneral",
          level: [0, 1],
        },
      ];
    },
  },

  methods: {
    getBusinessName() {
      if (this.selected) {
        if (this.ownersBusiness.length > 0) {
          let all = this.ownersBusiness;
          let currentBusiness = all.find((x) => x.id == this.selected);
          // console.log('my bix: ', JSON.stringify())
          this.busName = currentBusiness.businessName;
        } else {
          //Fetching current user details
          var data = jwtdecode(
            this.$store.state.token,
            this.$store.state.accessKey
          );
          db.collection("businesses")
            .doc(data.businessId)
            .get()
            .then((doc) => {
              this.busName = doc.data().businessName;
            });
        }
      }
      // let ownersBusinesses = this.$store.getters.getBusinesses;

      // this.busName =  currentBusiness.businessName
    },

    getNotifications() {
      this.notifications = [];
      db.collection("notifications")
        .where("ownerId", "==", this.$store.state.currentUser)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.notifications.push({
              id: doc.id,
              ...doc.data(),
            });
            this.notifications.sort(function (a, b) {
              return b.dateTime.seconds - a.dateTime.seconds;
            });
          });
          this.loading = false;
        });
    },
    //return number of notifications
    getNumberOfNotifications(item) {
      var size = item.length;

      return size;
    },

    formatDate(val) {
      var date = val.toDate();
      return format(date, "yyyy-MM-dd");
    },

    formatDateMinuteTime(val) {
      var date = val.toDate();
      return format(date, "hh:mm aa");
    },

    checkConnection() {
      this.internetConnection = navigator.onLine ? true : false;
      return this.internetConnection;
    },

    redirect(title, route) {
      this.$store.commit("SET_WINDOWTITLE", title);
      //route to set paths
      this.$router.push({ name: route });
    },

    reload() {
      this.$store.commit("SET_WINDOWTITLE", "Dashboard");
      this.$router.push({ name: "DashboardShop" });
    },

    // logout user

    logout: function () {
      let cleardata = {
        status: false,
        userId: "",
      };

      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      ); //Fetching current user details

      var currentLog = this.$store.state.logger; // Fetching Existing Logs  on store

      // Updating Log out Date and Time
      if (currentLog != "") {
        db.collection("logs").doc(currentLog).update({
          dateTimeOut: new Date(),
        });
      }

      // Update user session

      if (data.accessLevel.id != "") {
        db.collection("users").doc(data.accessLevel.id).update({
          status: false,
        });
      }

      //Clear Vuex Store data

      let clearLogs = "";
      let clearToken = "";

      this.$store.commit("SET_LOGS", clearLogs);
      this.$store.commit("SET_SESSION", cleardata);
      this.$store.commit("SET_TOKEN", clearToken);

      //Redirect to Login page and Clear Local storage data cache and
      var lang = localStorage.getItem("lang");

      localStorage.clear();

      this.$i18n.locale = lang;

      //store translation language to store
      localStorage.setItem("lang", lang);
      this.$router.push({ name: "Login" });
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang;
      //store translation language to store
      localStorage.setItem("lang", lang);
      this.$router.push({ params: { lang: lang } });
      // setTimeout(() => window.location.reload(), 500);
    },
    checkAccessLevel() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      this.userAccess = data.accessLevel.isSuperAdmin
        ? 0
        : data.accessLevel.isOwner
        ? 1
        : data.accessLevel.isManager
        ? 2
        : data.accessLevel.isChef
        ? 3
        : data.accessLevel.isWaiter
        ? 4
        : data.accessLevel.isCashier
        ? 5
        : 6;

      if (this.userAccess == 0) {
        this.ownerType = "SuperAdmin";
      } else if (this.userAccess == 1) {
        this.ownerType = "Owner";
      } else if (this.userAccess == 2) {
        this.ownerType = "Manager";
      } else if (this.userAccess == 3) {
        this.ownerType = "Chef";
      } else if (this.userAccess == 4) {
        this.ownerType = "Waiter";
      } else if (this.userAccess == 5) {
        this.ownerType = "Cashier";
      }
    },
    confirmDelete(id) {
      this.notificationId = id;
      this.dialogDelete = true;
    },
    deleteNotification() {
      this.loadingDelete = true;
      db.collection("notifications")
        .doc(this.notificationId)
        .delete()
        .then(() => {
          this.snackSuccess = true;
          this.dialogDelete = false;

          this.loadingDelete = false;
          this.getNotifications();
        })
        .catch(() => {
          this.snackError = true;
          this.dialogDelete = false;

          this.loadingDelete = false;
        });
    },
  },
};
</script>
